<template>
  <div class="overflow-scroll">
    <div style="min-width: 1170px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <a-card
          v-if="noTitleKey === 'baseDetail'"
          :bordered="false"
          style="margin-left: -32px"
        >
          <DescriptionList
            title="品牌信息"
            size="large"
            style="margin-top: -24px;margin-bottom: -48px"
          >
            <a-card
              :bordered="false"
              class="feeEditLabelWidth"
              style="margin-left: -16px"
            >
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌编号">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.thisDetailcode
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'name',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: defaultData.name
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌主体">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'entity',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: defaultData.entity
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌规模">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'scale',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(defaultData.scale)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in scaleList"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌状态">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'status',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请选择！'
                                }
                              ],
                              initialValue: this.showMessage(
                                this.thisDetailstatus
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in scaleStatus"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="销售负责人">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'salesPoc',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入!'
                                }
                              ],
                              initialValue: defaultData.salesPoc
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionList from "../../../components/DescriptionList";
import { modal } from "ant-design-vue";
  
import Bus from "../../../bus";

export default {
  name: "brandListEdit",
  components: {
    DescriptionList
  },
  data() {
    return {
      editDetailId: this.$route.query.editDetailId,
      thisDetailstatus: this.$route.query.thisDetailstatus,
      thisDetailcode: this.$route.query.thisDetailcode,
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      //品牌规模
      scaleList: [
        {
          id: 1,
          name: "全国品牌"
        },
        {
          id: 2,
          name: "区域品牌"
        },
        {
          id: 3,
          name: "地方品牌"
        }
      ],
      //品牌状态
      scaleStatus: [
        {
          id: 1,
          name: "已合作"
        },
        {
          id: 2,
          name: "未合作"
        }
      ],
      //经营品类
      cuisineTypeList: [
        { id: 1, name: "粤菜" },
        { id: 2, name: "川湘菜" },
        { id: 3, name: "江浙菜" },
        { id: 4, name: "快餐便当" },
        { id: 5, name: "简餐沙拉" },
        { id: 6, name: "米粉面馆" },
        { id: 7, name: "饺子馄饨" },
        { id: 8, name: "包子粥店" },
        { id: 9, name: "生煎锅贴" },
        { id: 10, name: "小吃烧烤" },
        { id: 11, name: "香锅干锅" },
        { id: 12, name: "海鲜龙虾" },
        { id: 13, name: "砂锅汤类" },
        { id: 14, name: "火锅" },
        { id: 15, name: "西餐" },
        { id: 16, name: "东南亚菜" },
        { id: 17, name: "日韩菜" },
        { id: 18, name: "意面披萨" },
        { id: 19, name: "汉堡薯条" },
        { id: 20, name: "其他地方菜系" },
        { id: 21, name: "甜品饮品" },
        { id: 22, name: "果蔬生鲜" },
        { id: 23, name: "鲜花绿植" },
        { id: 24, name: "医药健康" },
        { id: 25, name: "商品超市" },
        { id: 26, name: "服装鞋帽" },
        { id: 27, name: "美妆" }
      ],
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      defaultData: ""
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/brandList"
      });
    });
  },
  methods: {
    onTabChange(key, type) {
      console.log(key, type);
      this[type] = key;
    },
    init() {
      if (this.editDetailId) {
        axios({
          url:   this.$baseUrl + "/brand/query",
          method: "GET",
          params: {
            id: this.editDetailId
          }
        }).then(res => {
          if (res.data.obj) {
            this.defaultData = res.data.obj;
          }
        });
      }
    },
    //新建品牌
    brandCreate(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/brand/save",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({ path: "/role/brandList" });
            this.$message.success("新建品牌成功！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //编辑品牌
    brandEdit(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/brand/modify",
        headers: {
          Accept: "*/*"
        },
        data: {
          id: this.editDetailId,
          ...val
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({ path: "/role/brandList" });
            this.$message.success("品牌编辑成功！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //保存
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          if (!that.editDetailId) {
            that.brandCreate(values);
          } else {
            modal.confirm({
              title: "该页面信息已编辑，是否需要保存?",
              onOk() {
                that.brandEdit(values);
                return new Promise((resolve, reject) => {
                  setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log("Oops errors!"));
              },
              onCancel() {}
            });
          }
        }
      });
    },
    cancel() {
      this.$router.push({ path: "/role/brandList" });
    },
    showMessage(val) {
      return val ? val : undefined;
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
  /*margin-top: 24px;*/
}
</style>
