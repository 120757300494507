var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-scroll"},[_c('div',{staticStyle:{"min-width":"1170px"}},[_c('a-card',{attrs:{"bordered":false,"tabList":_vm.tabListNoTitle,"activeTabKey":_vm.noTitleKey},on:{"tabChange":key => _vm.onTabChange(key, 'noTitleKey')}},[(_vm.noTitleKey === 'baseDetail')?_c('a-card',{staticStyle:{"margin-left":"-32px"},attrs:{"bordered":false}},[_c('DescriptionList',{staticStyle:{"margin-top":"-24px","margin-bottom":"-48px"},attrs:{"title":"品牌信息","size":"large"}},[_c('a-card',{staticClass:"feeEditLabelWidth",staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'code',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: this.thisDetailcode
                          }
                        ]),expression:"[\n                          'code',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！'\n                              }\n                            ],\n                            initialValue: this.thisDetailcode\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'name',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: _vm.defaultData.name
                          }
                        ]),expression:"[\n                          'name',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！'\n                              }\n                            ],\n                            initialValue: defaultData.name\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌主体"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'entity',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: _vm.defaultData.entity
                          }
                        ]),expression:"[\n                          'entity',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！'\n                              }\n                            ],\n                            initialValue: defaultData.entity\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌规模"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'scale',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: this.showMessage(_vm.defaultData.scale)
                          }
                        ]),expression:"[\n                          'scale',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！'\n                              }\n                            ],\n                            initialValue: this.showMessage(defaultData.scale)\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.scaleList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'status',
                          {
                            rules: [
                              {
                                required: false,
                                message: '请选择！'
                              }
                            ],
                            initialValue: this.showMessage(
                              this.thisDetailstatus
                            )
                          }
                        ]),expression:"[\n                          'status',\n                          {\n                            rules: [\n                              {\n                                required: false,\n                                message: '请选择！'\n                              }\n                            ],\n                            initialValue: this.showMessage(\n                              this.thisDetailstatus\n                            )\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.scaleStatus),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"销售负责人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'salesPoc',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: _vm.defaultData.salesPoc
                          }
                        ]),expression:"[\n                          'salesPoc',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入!'\n                              }\n                            ],\n                            initialValue: defaultData.salesPoc\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1)],1)],1)],1)])],1)],1):_vm._e()],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.conserve}},[_vm._v(" 保存 ")])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }